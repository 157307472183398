<template>
  <div>
    <section class="ftco-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center mb-5">
            <div class="heading-section text-dark p-2">
              {{$t("useraut.slogan1")}}
            </div>
            <a :href="SITE_URL" class="urlclass">{{ SITE_URL }}</a>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-7 col-lg-5">
            <div class="login-wrap p-4 p-md-5">
              <div class="d-flex">
                <div class="w-100">
                  <h3 class="mb-4 text-dark d-flex align-items-center justify-content-center">{{$t("useraut.logincode")}}</h3>
                </div>
              </div>
              <form action="#" class="login-form" v-on:submit.prevent="getCode">
                <div class="form-group">
                  <div class="icon d-flex align-items-center justify-content-center"><span class="fa fa-lock"></span></div>
                  <input
                    :disabled="disabled == 1"
                    v-on:keyup="onEnter($event)"
                    type="number"
                    class="form-control rounded-left"
                    name="code"
                    v-model="code"
                    placeholder="000000"
                    autocomplete="off"
                    maxlength="6"
                    required
                  />
                  <!--begin::Visibility toggle-->
                  <span @click="pasteButton" class="
                      btn btn-sm btn-icon
                      position-absolute
                      translate-middle
                      top-50
                      end-0
                      me-2
                      text-primary
                    ">
                    Paste
                  </span>
                  <!--end::Visibility toggle-->
                </div>
                <div class="icon d-flex align-items-center justify-content-center"></div>
                <div class="form-group d-flex justify-content-center">
                  <div v-if="buttonDisabled === 0" class="w-100 d-flex justify-content-center">
                    <button
                      type="submit"
                      class="btn btn-primary submit ps-15 pe-15"
                    >
                      {{$t("useraut.login")}}
                    </button>
                  </div>
                </div>
                <div class="form-group d-flex justify-content-center">
                <div v-if="buttonDisabled === 1" class="w-100 d-flex justify-content-center">
                  <button type="button" class="btn btn-primary submit ps-15 pe-15">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DataService from "../services/DataService";
import { minLength, maxLength, required } from "vuelidate/lib/validators";
export default {
  name: "logincode",
  metaInfo: function() {
    return {
      title: this.$t("metatag.titledefault"),
      titleTemplate: "%s | "+this.$t("metatag.titleDetailHome"),
      meta: [
      { name: 'description', content:  this.$t("metatag.description") },
      { name: 'keywords', content:  this.$t("metatag.keywords") }
      ]
    }
  },
  data() {
    return {
      code: "",
      disabled: 0,
      term: "",
      browser: "",
      browserLang: "",
      platform: "",
      linktekrar: 0,
      confirmlink: "",
      SITE_URL: process.env.VUE_APP_SITE_URL,
      buttonDisabled:0
    };
  },
  validations: {
    code: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },
  },
  methods: {
    userActivity() {
      DataService.userActivity().then((response) => {
        this.term = response.data.ip;
      });
      this.browser = navigator.userAgent;
      this.browserLang = navigator.language;
      this.platform = navigator.platform;
    },
    async createToken(
      data0 = "null",
      data1 = "null",
      data2 = "null",
      data3 = "null",
      data4 = "null",
      data5 = "null",
      data6 = "null",
      data7 = "null",
      data8 = "null",
      data9 = "null",
      data10 = "null"
    ) {
      var send = [];
      await DataService.veriSifrele([
        data0,
        data1,
        data2,
        data3,
        data4,
        data5,
        data6,
        data7,
        data8,
        data9,
        data10,
      ]).then((response) => {
        send.push(response.data);
      });
      return send;
    },
    async getCode() {
      if (this.$session.get("geciciemail")) {
        if (this.code != "") {
          if (this.$v.$invalid === false) {
            this.buttonDisabled = 1;
            DataService.loginCode({
              token: await this.createToken(
                this.$session.get("geciciemail"),
                this.code
              ),
              activity: await this.createToken(
                this.term,
                this.browser,
                this.browserLang,
                this.platform
              ),
            })
              .then((response) => {
                if (!response.data.error && response.data.status == "ok") {
                  this.$vToastify.setSettings({
                    position: "bottom-right",
                    theme: "light",
                    successDuration: 2000,
                  });
                  this.$vToastify.success("",this.$t('useraut.logging'));
                  this.$session.set("logtoken", response.data.logtoken);
                  this.$session.set("logemail",this.$session.get("geciciemail"));
                  this.$session.set("logID", response.data.userid);
                  this.$session.set("logMeta", false);
                  const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
                  setTimeout(() => {
                    window.location.href = `/${this.$session.get("lang")}/dashboard`;
                  }, 1000);
                } else {
                  this.disabled = 0;
                  this.buttonDisabled = 0;
                  this.$vToastify.setSettings({
                    position: "bottom-right",
                    theme: "light",
                  });
                  this.$vToastify.warning("",this.$t("message." + response.data.error)
                  );
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.$vToastify.setSettings({
              position: "bottom-right",
              theme: "light",
            });
            this.$vToastify.warning(this.$t('useraut.errorform'));
          }
        } else {
          this.$vToastify.setSettings({
            position: "bottom-right",
            theme: "light",
          });
          this.$vToastify.warning(this.$t('useraut.blankfield'));
        }
      } else {
        this.$vToastify.setSettings({
          position: "bottom-right",
          theme: "light",
        });
        this.$vToastify.warning("", this.$t('useraut.loginfirst'));
        this.$router.push(`/${this.$session.get("lang")}/login`);
      }
    },
    onEnter(event) {
      if (event.currentTarget.value.length === 6) {
        this.disabled = 1;
        this.getCode();
      }
    },
    pasteButton(){
      navigator.clipboard.readText()
      .then((text)=>{
          this.code = text;
      });
    },
    sessionKontrol() {
      if (!this.$session.get("logtoken")) {
        if (!this.$session.get("geciciemail")) {
          this.$vToastify.setSettings({
            position: "bottom-right",
            theme: "light",
          });
          this.$vToastify.warning("", "Önce giriş yapın!");
          this.$router.push(`/${this.$session.get("lang")}/login`);
        }
      }
    },
  },
  mounted() {
    this.sessionKontrol();
    this.userActivity();
  },
};
</script>
<style lang="" scoped src="../assets/css/login.css">
</style>
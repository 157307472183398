<template>
  <div>
    <Logincode></Logincode>
  </div>
</template>

<script>
// @ is an alias to /src
import Logincode from '@/components/Logincode.vue'

export default {
  name: 'Logincodeview',
  components: {
    Logincode,
  }
}
</script>
